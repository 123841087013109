import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import css from './SettingsLayoutWrapper.module.css';
import classnames from 'classnames';

import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faWallet,
  faTags,
  faCalendar,
  faPalette,
  faCalendarCheck,
  faComments,
} from '@fortawesome/free-solid-svg-icons';
import { closeNotification, showNotification } from '../../../custom-ducks/utility.duck';
import ReactTooltip from 'react-tooltip';
import { MessageCircle } from 'react-feather';

function SettingsLayoutWrapper(props) {
  const { page, children, currentUser } = props;
  const [isSlided, setIsSlided] = useState(false);

  const goToUrl = path => {
    props.history.push(path);
  };

  // const pubData = currentUser && currentUser.attributes.profile.publicData;

  const isProvider = currentUser && currentUser.attributes.profile.publicData.isProvider;
  // const hasEarlyAccess = currentUser && currentUser.attributes.profile.metadata.hasEarlyAccess;
  // const isInternalUser = currentUser && currentUser.attributes.profile.metadata.isInternalUser;
  // const isVCoachAvailable = isProvider && (hasEarlyAccess || isInternalUser);

  useEffect(() => {
    console.log(window.menuOpenState);
    if (window.menuOpenState) {
      console.log(1);
      document.getElementById('mySidenav').style.width = '200px';
      document.getElementById('mySidenav').style.transition = 'none';
      document.getElementById('mainWrapperDiv').style.transition = 'none';
      document.getElementById('mySidenav').style.left = '0';
    }
  }, [!window.menuOpenState]);

  return (
    <div className={css.wrapper}>
      {/*<h2 className={css.header}>Settings</h2>*/}

      {/*<div className={css.line}></div>*/}
      <div className={css.base}>
        <section className={classnames(css.left)}>
          {isProvider && (
            <div
              onClick={() => page && page !== 'saas_settings' && goToUrl('/v-coach/settings')}
              className={page && page === 'saas_settings' ? css.selected : css.nonSelected}
            >
              <FontAwesomeIcon className={css.icon} icon={faCalendar} />
              <p>Calendar</p>
            </div>
          )}

          {isProvider && (
            <div
              onClick={() => page && page !== 'saas_inquiries' && goToUrl('/inbox/sales')}
              className={page && page === 'saas_inquiries' ? css.selected : css.nonSelected}
            >
              <FontAwesomeIcon className={css.icon} icon={faComments} />
              {/* <MessageCircle /> */}
              <p>Inquiries</p>
            </div>
          )}

          {isProvider && (
            <div
              onClick={() => page && page !== 'payments' && goToUrl('/account/payments/pricing')}
              className={page && page === 'payments' ? css.selected : css.nonSelected}
            >
              <FontAwesomeIcon className={css.icon} icon={faUser} />
              <p>Coach subscription</p>
            </div>
          )}

          {isProvider && (
            <div
              onClick={() =>
                page && page !== 'withdrawal' && goToUrl('/account/payments/withdrawal')
              }
              className={page && page === 'withdrawal' ? css.selected : css.nonSelected}
            >
              <FontAwesomeIcon className={css.icon} icon={faWallet} />
              <p>Payout details</p>
            </div>
          )}

          <div
            onClick={() => page && page !== 'account_settings' && goToUrl('/account-settings')}
            className={page && page === 'account_settings' ? css.selected : css.nonSelected}
          >
            <FontAwesomeIcon className={css.icon} icon={faTags} />
            <p>Account</p>
          </div>

          {isProvider && (
            <div
              onClick={() => page && page !== 'branding' && goToUrl('/account/branding')}
              className={page && page === 'branding' ? css.selected : css.nonSelected}
            >
              <FontAwesomeIcon className={css.icon} icon={faPalette} />
              <p>Coach Branding</p>
            </div>
          )}
        </section>
        {/*<div className={css.slideDown} onClick={e => setIsSlided(!isSlided)}>*/}
        {/*  <span></span>*/}
        {/*  <span></span>*/}
        {/*  <span></span>*/}
        {/*</div>*/}
        <section className={css.right}>{children}</section>
      </div>
      <ReactTooltip className={css.tooltip} place={'right'} effect={'solid'} delayHide={10} />
    </div>
  );
}

const mapStateToProps = state => {
  const { currentUserNotificationCount: notificationCount, currentUser } = state.user;
  const { notification } = state.utility;

  return {
    notificationCount,
    currentUser,
    notification,
  };
};

const mapDispatchToProps = dispatch => ({
  notify: data => {
    dispatch(showNotification(data));
  },

  closeNotification: () => {
    dispatch(closeNotification());
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SettingsLayoutWrapper);
