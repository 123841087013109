import React, { useState } from 'react';
import css from './PublicPageWrapper.css';
import Collapse from '@kunukn/react-collapse';
import {
  Menu as MenuIcon,
  Grid,
  FileText,
  ChevronDown,
  ChevronUp,
  Activity,
  MessageCircle,
  Mail,
  PieChart,
  ChevronLeft,
  Users,
  User,
  CreditCard,
  Settings,
  Link2,
  ShoppingBag,
} from 'react-feather';
import AppLogo from '../../assets/viveka-logo.png';
import { Link, NavLink, withRouter } from 'react-router-dom';
import config from '../../config';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Menu, MenuItem, SubMenu } from '@szhsin/react-menu';
import { logout } from '../../ducks/Auth.duck';
import defaultProfilePic from '../../assets/default_profile.png';
import classNames from 'classnames';

const PublicPageWrapper = props => {
  const {
    currentUser,
    children,
    title,
    page,
    history,
    logout,
    isAuthenticated,
    currentUserNotificationCount,
  } = props;

  const [isVCorpOpen, setIsVCorpOpen] = useState(true);
  const [isVCoachOpen, setIsVCoachOpen] = useState(true);
  const [isVClientOpen, setIsVClientOpen] = useState(true);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const isProvider = currentUser?.attributes?.profile?.publicData?.isProvider;
  const myCompanies =
    currentUser?.attributes?.profile?.metadata[`myCompanies${process.env.REACT_APP_ENVIRONMENT}`];
  const hasCompany = !!myCompanies;
  const currentCompany =
    currentUser?.attributes?.profile?.metadata[
      `currentCompany${process.env.REACT_APP_ENVIRONMENT}`
    ];
  const currentCompanyRoles = currentCompany?.roles || [];

  const menuItemStyles = {
    hover: {
      backgroundColor: '#007373',
      color: 'white',
    },
    active: {
      backgroundColor: '#007373',
      color: 'white',
    },
  };

  // console.log({ isNavOpen });

  return (
    <div className={css.base} style={{ display: isAuthenticated ? 'grid' : 'flex' }}>
      <div
        className={`${css.menu_base} ${isNavOpen && css.menu_open} ${!isAuthenticated &&
          css.hide_menu}`}
        // style={{ display: !isAuthenticated ? 'none' : '' }}
      >
        <div className={css.menu_nav_top}>
          {isAuthenticated && (
            <Link to={'/s'} className={css.logo}>
              <img style={{ width: '100%' }} alt={'logo'} src={AppLogo} />
            </Link>
          )}

          <ChevronLeft className={css.menu_close_button} onClick={() => setIsNavOpen(false)} />
        </div>

        <div className={classNames(css.section_wrapper)}>
          {currentCompany &&
            (currentCompanyRoles.includes('admin') || currentCompanyRoles.includes('hr')) && (
              <div className={css.menu_section}>
                <div className={css.menu_item_wrapper} onClick={() => setIsVCorpOpen(!isVCorpOpen)}>
                  <p>V-Corp</p>
                  {isVCorpOpen ? <ChevronUp /> : <ChevronDown />}
                </div>
                <Collapse isOpen={isVCorpOpen}>
                  <a
                    href={`${config.vCorpUrl}/hr/dashboard`}
                    target="_self"
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-dashboard' ? css.menu_item_active : ''
                    }`}
                  >
                    <Grid />
                    <p>Dashboard</p>
                  </a>
                  <a
                    href={`${config.vCorpUrl}/hr/curriculum`}
                    target="_self"
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-curriculum' ? css.menu_item_active : ''
                    }`}
                  >
                    <FileText />
                    <p>Curriculum</p>
                  </a>
                  <a
                    href={`${config.vCorpUrl}/hr/coach`}
                    target="_self"
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-coach' ? css.menu_item_active : ''
                    }`}
                  >
                    <User />
                    <p>Coach</p>
                  </a>

                  <a
                    href={`${config.vCorpUrl}/hr/employee`}
                    target="_self"
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-employees' ? css.menu_item_active : ''
                    }`}
                  >
                    <Users />
                    <p>Employees</p>
                  </a>

                  <a
                    href={`${config.vCorpUrl}/hr/billing/budget`}
                    target="_self"
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-billing' ? css.menu_item_active : ''
                    }`}
                  >
                    <CreditCard />
                    <p>Billing</p>
                  </a>

                  <a
                    href={`${config.vCorpUrl}/hr/settings`}
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-settings' ? css.menu_item_active : ''
                    }`}
                  >
                    <Settings />
                    <p>Settings</p>
                  </a>
                </Collapse>
              </div>
            )}

          {isProvider && (
            <div className={css.menu_section}>
              <div className={css.menu_item_wrapper} onClick={() => setIsVCoachOpen(!isVCoachOpen)}>
                <p>V-Coach</p>
                {isVCoachOpen ? <ChevronUp /> : <ChevronDown />}
              </div>
              <Collapse isOpen={isVCoachOpen}>
                <Link
                  to={`/v-coach/clients-activities`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-dashboard' ? css.menu_item_active : ''
                  }`}
                >
                  <Grid />
                  <p>Dashboard</p>
                </Link>
                <Link
                  to={`/v-coach/programs`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-program' ? css.menu_item_active : ''
                  }`}
                >
                  <FileText />
                  <p>Programs</p>
                </Link>
                <Link
                  to={`/listings`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-ads' ? css.menu_item_active : ''
                  }`}
                >
                  <Activity />
                  <p>Ads</p>
                </Link>

                <Link
                  to={`/saas-chats/coach`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-messages' ? css.menu_item_active : ''
                  }`}
                >
                  <Mail />
                  <p>Messages</p>
                </Link>

                <Link
                  to={`/referral_program`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-referral' ? css.menu_item_active : ''
                  }`}
                >
                  <Users />
                  <p>Referrals</p>
                </Link>
              </Collapse>
            </div>
          )}

          {isAuthenticated && (
            <div className={css.menu_section}>
              <div
                className={css.menu_item_wrapper}
                onClick={() => setIsVClientOpen(!isVClientOpen)}
              >
                <p>V-Client</p>
                {isVClientOpen ? <ChevronUp /> : <ChevronDown />}
              </div>
              <Collapse isOpen={isVClientOpen}>
                {hasCompany && (
                  <a
                    href={`${config.vCorpUrl}/employee/company/my-curriculums`}
                    className={css.collapse_item_wrapper}
                  >
                    <FileText />
                    <p>{currentCompany?.name || 'My Company'}</p>
                  </a>
                )}
                <Link
                  to={`/v-coach/client/dashboard`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'client-dashboard' ? css.menu_item_active : ''
                  }`}
                >
                  <Grid />
                  <p>Dashboard</p>
                </Link>

                {/* <Link
                  to={`/v-coach/client/my-activities`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'client-activity' ? css.menu_item_active : ''
                  }`}
                >
                  <PieChart />
                  <p>My Activities</p>
                </Link> */}
                <Link
                  to={`/saas-chats/client`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'client-messages' ? css.menu_item_active : ''
                  }`}
                >
                  <Mail />
                  <p>Messages</p>
                </Link>

                <Link
                  to={`/inbox/orders`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'client-purchases' ? css.menu_item_active : ''
                  }`}
                >
                  <MessageCircle />
                  <p>My Purchases</p>
                </Link>

                <Link
                  to={`/s`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'client-marketplace' ? css.menu_item_active : ''
                  }`}
                >
                  <ShoppingBag />
                  <p className=" text-ellipsis overflow-hidden whitespace-nowrap">Find A Coach</p>
                </Link>
              </Collapse>
            </div>
          )}

          <div className={css.public_links}>
            <hr />
            <div className={css.menu_section}>
              <Link to={`/how-it-works`} className={css.collapse_item_wrapper}>
                <Link2 />
                <p>How it works</p>
              </Link>

              <Link to={`/enterprise`} className={css.collapse_item_wrapper}>
                <Link2 />
                <p>Enterprise</p>
              </Link>

              {/*<Link to={`/coach`} className={css.collapse_item_wrapper}>*/}
              {/*  <Link2 />*/}
              {/*  <p>Coach</p>*/}
              {/*</Link>*/}
              <a href={`/coach`} className={css.collapse_item_wrapper}>
                <Link2 />
                <p>Coach</p>
              </a>

              <Link to={`/individual`} className={css.collapse_item_wrapper}>
                <Link2 />
                <p>Individual</p>
              </Link>
            </div>
          </div>

          <hr />

          {isAuthenticated && (
            <div className={css.menu_section}>
              {/* <Link
                to={`/inbox/sales`}
                className={`${css.collapse_item_wrapper} ${
                  page === 'coach-inquiry' ? css.menu_item_active : ''
                }`}
              >
                <MessageCircle />
                <p>Inquiries</p>
              </Link> */}

              <Link
                to={`/account-settings`}
                className={`${css.collapse_item_wrapper} ${
                  page === 'all-setting' ? css.menu_item_active : ''
                }`}
              >
                <Settings />
                <p>User settings</p>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className={css.content_base} style={{ width: !isAuthenticated ? '100vw' : '' }}>
        <div className={css.nav_bar}>
          <div id="leftItems" style={{ display: 'flex', marginLeft: '20px', alignItems: 'center' }}>
            {/*{isAuthenticated && (*/}
            <MenuIcon className={css.menu_button} onClick={() => setIsNavOpen(true)} />
            {/*)}*/}

            {/*{!isAuthenticated && (*/}

            {/*    <a href={'/'} className={css.logo}>*/}
            {/*      <img style={{ width: '100%' }} alt={'logo'} src={AppLogo} />*/}
            {/*    </a>*/}

            {/*)}*/}

            {!isAuthenticated && (
              <a className={css.logo} href="/">
                <img style={{ width: '100%' }} alt={'logo'} src={AppLogo} />
              </a>
            )}

            <div className={css.nav_links}>
              {/*<NavLink exact to={'/'} activeClassName={css.link_active}>*/}
              {/*  Home*/}
              {/*</NavLink>*/}
              {/*<NavLink exact to={'/how-it-works'} activeClassName={css.link_active}>*/}
              {/*  How it works*/}
              {/*</NavLink>*/}
              {/*<NavLink exact to={'/enterprise'} activeClassName={css.link_active}>*/}
              {/*  Enterprise*/}
              {/*</NavLink>*/}
              {/*<NavLink exact to={'/coach'} activeClassName={css.link_active}>*/}
              {/*  Coach*/}
              {/*</NavLink>*/}
              {/*<a href={'/coach'}>*/}
              {/*  Coach*/}
              {/*</a>*/}
              {/*<NavLink exact to={'/individual'} activeClassName={css.link_active}>*/}
              {/*  Individual*/}
              {/*</NavLink>*/}
            </div>
          </div>
          {/*<p className={css.nav_title}>{title}</p>*/}
          <div className={css.nav_actions}>
            {isAuthenticated ? (
              <>
                <Mail
                  onClick={() => history.push('/public-messages')}
                  className={css.nav_message_button}
                />

                <Menu
                  direction="left"
                  menuButton={
                    <div className={css.avatar_wrapper}>
                      {/*<Avatar src={currentUser?.profilePic}>{avatarName}</Avatar>*/}
                      <div>
                        <img
                          className={css.profile_image}
                          alt="profile"
                          src={
                            currentUser?.profileImage?.attributes?.variants['square-small'].url ||
                            defaultProfilePic
                          }
                        />
                      </div>
                      <div>
                        <p>{currentUser?.attributes?.profile.displayName}</p>
                      </div>
                      {currentUserNotificationCount > 0 && (
                        <div
                          style={{
                            color: 'red',
                          }}
                          className="bg-red-200 rounded-full w-5 h-5 animate-pulse text-xs flex items-center justify-center"
                        >
                          {currentUserNotificationCount}
                        </div>
                      )}
                    </div>
                  }
                >
                  {isProvider && (
                    <MenuItem
                      styles={menuItemStyles}
                      onClick={() => {
                        history.push('/inbox/sales');
                        return true;
                      }}
                      className={'flex gap-2'}
                    >
                      My sales{' '}
                      {currentUserNotificationCount > 0 && (
                        <div className="bg-red-200 rounded-full w-5 h-5 text-red-600 text-xs flex items-center justify-center">
                          {currentUserNotificationCount}
                        </div>
                      )}
                    </MenuItem>
                  )}

                  <MenuItem
                    styles={menuItemStyles}
                    onClick={() => {
                      history.push('/profile-settings');
                      return true;
                    }}
                  >
                    My Profile
                  </MenuItem>
                  <MenuItem
                    styles={menuItemStyles}
                    onClick={() => {
                      // logoutA().then(() => history.push('/auth/login'))
                      logout().then(() => history.push('/login'));
                      return true;
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <div className={css.authActions}>
                <Link className={css.loginButton} to={'/login'}>
                  Login
                </Link>

                <Link className={css.signupButton} to={'/signup'}>
                  Signup
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className={css.content}>{children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser, currentUserNotificationCount } = state.user;
  const { isAuthenticated } = state.Auth;

  return {
    currentUser,
    isAuthenticated,
    currentUserNotificationCount,
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

export default withRouter(compose(connect(mapStateToProps, mapDispatchToProps))(PublicPageWrapper));
