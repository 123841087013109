/* eslint-disable react/no-direct-mutation-state*/
import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
// import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  Avatar,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  // FieldCheckbox,
} from '../../components';

import css from './ProfileSettingsForm.css';
import { faLock, faMobile, faPaperclip, faUser } from '@fortawesome/free-solid-svg-icons';
// import config from '../../config';
// import { types as sdkTypes } from '../../util/sdkLoader';
import Button from '../../components/Button/Button';
import Geocoder from 'react-mapbox-gl-geocoder';
import ReactMapGL from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldTip from '../../custom-components/elements/field-tip/FieldTip';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import ReactQuill from 'react-quill';
import { decode, encode } from 'html-entities';
// import FieldSelect from '../../components/FieldSelect/FieldSelect';
// const { LatLng } = sdkTypes;

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

const mapAccess = {
  mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
};

const mapStyle = {
  width: '100%',
  height: 350,
};

const queryParams = {
  //country: 'us'
};

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = {
      uploadDelay: false,
      viewport: {},
      selectedLocation: '',
      bioHtml: '',
    };
    this.submittedValues = {};

    this.onBioChange = this.onBioChange.bind(this);
  }

  componentDidMount() {
    const { currentUser } = this.props;
    this.setState({ bioHtml: currentUser.attributes.profile.bio });
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }

    var locationField = document.querySelector('#location_search input');
    locationField.setAttribute('placeholder', 'Location');
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  onSelected = (viewport, item) => {
    this.setState({ viewport: viewport });
    this.state.selectedLocation = item.place_name;
  };

  onBioChange(html) {
    const htmlText = html.replace(/<[^>]+>/g, '');
    this.setState({ bioHtml: encode(html) });
  }

  render() {
    const { viewport } = this.state;

    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            // invalid,
            onImageUpload,
            // pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          // First name
          // const firstNameLabel = intl.formatMessage({
          //   id: 'ProfileSettingsForm.firstNameLabel',
          // });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          // const lastNameLabel = intl.formatMessage({
          //   id: 'ProfileSettingsForm.lastNameLabel',
          // });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // Last name
          // const displayNameLabel = intl.formatMessage({
          //   id: 'ProfileSettingsForm.displayNameLabel',
          // });
          const displayNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.displayNamePlaceholder',
          });
          const displayNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.displayNameRequired',
          });
          const displayNameRequired = validators.required(displayNameRequiredMessage);

          // const userNamePlaceholder = intl.formatMessage({
          //   id: 'ProfileSettingsForm.userNamePlaceholder',
          // });
          // const userNameRequiredMessage = intl.formatMessage({
          //   id: 'ProfileSettingsForm.userNameRequired',
          // });
          // const userNameRequired = validators.required(userNameRequiredMessage);
          //
          // const locationPlaceholder = intl.formatMessage({
          //   id: 'ProfileSettingsForm.locationPlaceholder',
          // });
          // const locationRequiredMessage = intl.formatMessage({
          //   id: 'ProfileSettingsForm.locationRequired',
          // });
          // const locationRequired = validators.required(locationRequiredMessage);

          const phonePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.phonePlaceholder',
          });
          const phoneRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.phoneRequired',
          });
          const phoneRequired = validators.required(phoneRequiredMessage);

          // Bio
          // const bioLabel = intl.formatMessage({
          //   id: 'ProfileSettingsForm.bioLabel',
          // });
          const bioPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.bioPlaceholder',
          });
          // const facebookPlaceholder = intl.formatMessage({
          //   id: 'ProfileSettingsForm.facebookPlaceholder',
          // });
          // const twitterPlaceholder = intl.formatMessage({
          //   id: 'ProfileSettingsForm.twitterPlaceholder',
          // });
          // const instagramPlaceholder = intl.formatMessage({
          //   id: 'ProfileSettingsForm.instagramPlaceholder',
          // });
          // const youtubePlaceholder = intl.formatMessage({
          //   id: 'ProfileSettingsForm.youtubePlaceholder',
          // });

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <div className={css.avatarBox}>
                <Avatar
                  className={avatarClasses}
                  renderSizes="(max-width: 767px) 96px, 240px"
                  user={transientUser}
                  disableProfileLink
                />
              </div>
            ) : null;

          const onImageClick = () => {
            var imageField = document.querySelector('#profileImage');
            imageField.click();
          };

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatarContainer}>
                  <div className={css.changeAvatar} onClick={onImageClick}>
                    <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                  </div>

                  {/*<div className={css.tip}>
                    <FormattedMessage id="ProfileSettingsForm.tip" />
                  </div>
                  <div className={css.fileInfo}>
                    <FormattedMessage id="ProfileSettingsForm.fileInfo" />
                  </div>*/}
                </div>
              </div>
            ) : (
              <div onClick={onImageClick} className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          // const submittedOnce = Object.keys(this.submittedValues).length > 0;
          // const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          // const submitDisabled =
          //   invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;
          // const mapData = {
          //   center: new LatLng(60.16502999999999, 24.940064399999983),
          //   //obfuscatedCenter: new LatLng(60.16502999999999, 24.940064399999983),
          //   address: 'Tucson, AZ, USA',
          //   zoom: 14,
          //   mapsConfig: {
          //     ...config.maps,
          //     fuzzy: {
          //       ...config.maps.fuzzy,
          //       enabled: true,
          //     },
          //   },
          // };

          // console.log('Form values"');
          // console.log(values);
          const quillModules = {
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [
                'link',
                // 'image'
              ],
              ['clean'],
            ],
          };

          const quillFormats = [
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            // 'image',
          ];

          return (
            <Form
              className={classes}
              onSubmit={e => {
                // console.log(this.state.selectedLocation);
                values.location = this.state.selectedLocation;
                values.bio = this.state.bioHtml;

                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.top_visibility_message}>
                <FormattedMessage id="ProfileSettingsForm.visibility_message" />
              </div>

              <div className={css.form_field}>
                <FieldTextInput
                  className={css.firstName}
                  type="text"
                  id="firstName"
                  icon={faUser}
                  name="firstName"
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
              </div>
              <div className={css.form_field}>
                <FieldTextInput
                  type="text"
                  id="lastName"
                  icon={faUser}
                  name="lastName"
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
              </div>
              <div className={css.form_field}>
                <FieldTextInput
                  type="text"
                  id="displayName"
                  icon={faUser}
                  name="displayName"
                  placeholder={displayNamePlaceholder}
                  validate={displayNameRequired}
                />

                <FieldTip
                  className={css.fieldTip}
                  message={<FormattedMessage id="ProfileSettingsForm.tip_displayname" />}
                />
              </div>
              {/*<div className={css.form_field}>
                <FieldTextInput
                  type="text"
                  id="abbreviatedName"
                  icon={faUser}
                  name="abbreviatedName"
                  placeholder={userNamePlaceholder}
                  validate={userNameRequired}
                />

                <FieldTip message={<FormattedMessage id="ProfileSettingsForm.tip_username" />}/>
              </div>*/}
              <div className={css.form_field}>
                <div id="location_search" className={css.locationSearchBox}>
                  <FontAwesomeIcon className={css.locationIcon} icon={faLock} />
                  <Geocoder
                    className={css.locationSearch}
                    name="location"
                    placeholder={'Search'}
                    initialInputValue={values.location}
                    updateInputOnSelect={true}
                    {...mapAccess}
                    onSelected={this.onSelected}
                    viewport={viewport}
                    hideOnSelect={true}
                    queryParams={queryParams}
                  />
                </div>
                <div className={css.locationMap}>
                  <ReactMapGL
                    {...mapAccess}
                    {...viewport}
                    {...mapStyle}
                    mapStyle="mapbox://styles/mapbox/streets-v8"
                    onViewportChange={newViewport => this.setState({ viewport: newViewport })}
                  />
                </div>
              </div>

              <div className={css.form_field}>
                <FieldTextInput
                  type="text"
                  id="phone"
                  icon={faMobile}
                  name="phone"
                  placeholder={phonePlaceholder}
                  validate={phoneRequired}
                />
              </div>

              <div className={css.form_field}>
                <Field
                  accept={ACCEPT_IMAGES}
                  id="profileImage"
                  name="profileImage"
                  label={chooseAvatarLabel}
                  type="file"
                  form={null}
                  uploadImageError={uploadImageError}
                  disabled={uploadInProgress}
                >
                  {fieldProps => {
                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onImageUpload({ id: tempId, file });
                      }
                    };

                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                        </div>
                      );
                    } else if (uploadImageError) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                        </div>
                      );
                    }

                    return (
                      <div className={css.uploadAvatarWrapper}>
                        {label}
                        <input
                          accept={accept}
                          id={id}
                          name={name}
                          className={css.uploadAvatarInput}
                          disabled={disabled}
                          onChange={onChange}
                          type={type}
                        />
                        {error}
                      </div>
                    );
                  }}
                </Field>
              </div>

              <div className={css.form_field}>
                <div className={css.about}>
                  {/*<FieldTextInput*/}
                  {/*  className={css.about_field}*/}
                  {/*  icon={faUser}*/}
                  {/*  type="textarea"*/}
                  {/*  id="bio"*/}
                  {/*  name="bio"*/}
                  {/*  placeholder={bioPlaceholder}*/}
                  {/*  validate={validators.minLength(*/}
                  {/*    'Your new clients want to get to know you. Your description should be at least 500 characters long. Mention your experience, achievements and certifications.',*/}
                  {/*    500*/}
                  {/*  )}*/}
                  {/*/>*/}

                  <p className="font-bold">Add bio below</p>
                  <ReactQuill
                    placeholder={`Please share a bit about yourself. You can include:
                      
Your Bio: Tell us about your background, qualifications, and areas of expertise.
Your Experience: Highlight your professional experience, coaching certifications, and industries you have worked in.
What Clients Can Expect: Share your coaching approach, methodologies, and what clients can expect when working with you including testimonials.`}
                    value={decode(this.state.bioHtml)}
                    onChange={e => this.onBioChange(e)}
                    modules={quillModules}
                    formats={quillFormats}
                    style={{
                      height: 400,
                      paddingBottom: 40,
                    }}
                  />
                </div>
                {/* <p className={css.bioInfo}>
                  <FormattedMessage id="ProfileSettingsForm.bioInfo" />
                </p> */}
              </div>

              {/* <div className={css.form_field}>
                <FieldSelect id="userType" name="userType" label="Choose an option:">
                  <option value="provider">I am a Provider</option>
                  <option value="customer">I am a customer</option>
                </FieldSelect>
              </div> */}

              <div className={css.form_field}>
                <FieldTextInput
                  type="text"
                  id="linedin"
                  icon={faLinkedin}
                  name="linkedin"
                  placeholder="Your Linked In profile full url"
                />
              </div>

              <div className={css.form_field}>
                <FieldTextInput
                  type="text"
                  id="facebook"
                  icon={faFacebook}
                  name="facebook"
                  placeholder="Facebook full url"
                />
              </div>
              <div className={css.form_field}>
                <FieldTextInput
                  type="text"
                  id="twitter"
                  icon={faTwitter}
                  name="twitter"
                  placeholder="Twitter full url"
                />
              </div>
              <div className={css.form_field}>
                <FieldTextInput
                  type="text"
                  id="instagram"
                  icon={faInstagram}
                  name="instagram"
                  placeholder="Instagram full url"
                />
              </div>
              <div className={css.form_field}>
                <FieldTextInput
                  type="text"
                  id="youtube"
                  icon={faYoutube}
                  name="youtube"
                  placeholder="Youtube full url"
                />
              </div>

              <div className={css.form_field}>
                <FieldTextInput
                  type="text"
                  id="other"
                  icon={faPaperclip}
                  name="other"
                  placeholder="Other url"
                />
              </div>

              <div>{submitError}</div>

              <div className={css.form_field_right}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  // disabled={submitDisabled}
                  // ready={pristineSinceLastSubmit}
                >
                  <FormattedMessage id="ProfileSettingsForm.saveChanges" />
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
