import React, { useEffect } from 'react';
import css from './PageWrapper.module.css';
import '../../css/global.css';
import { Helmet } from 'react-helmet-async';
import VivekaLogo from '../../../assets/meta/viveka-world.png';
import { withRouter } from 'react-router-dom';
import { config } from '../../../index';
import { requestSocket } from '../../../custom-ducks/socket.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SideChat from '../../elements/side-chat/SideChat';
import { addChat, addMessage } from '../../../custom-ducks/socketChat.duck';
import { setCheckBookingInterval } from '../../../custom-ducks/utility.duck';
import { fetchCurrentUserNotifications } from '../../../ducks/user.duck';
import StepsInViveka from '../../elements/steps-in-viveka/StepsInViveka';
import VideoCallDrawer from '../../elements/video-call-drawer/VideoCallDrawer';
// import GuidForPages from '../../elements/guid-for-pages/GuidForPages';

function PageWrapper(props) {
  let {
    children,
    title,
    description,
    image,
    url,
    keywords = [],
    schema,
    onConnectSession,
    // socket,
    currentUser,
    // isSocketConnectable,
    chats,
    onAddMessage,
    onAddChat,
    onSetCheckBookingInterval,
    checkBookingInterval,
    onFetchUserNotifications,
    topOffset,
  } = props;

  const setEvents = socketCon => {
    if (!socketCon._callbacks['$exchange-message']) {
      socketCon.on('exchange-message', async (id, message, displayName, dateTime) => {
        const selectedUser = chats.filter(x => x.userId === id);
        if (!selectedUser) {
          onAddMessage({
            userId: id,
            text: message,
            isFromMe: false,
            dateTime,
          });
        } else {
          await onAddChat({
            name: displayName,
            userId: id,
            isOpened: true,
            // isOnline: false,
            messages: [],
          });

          onAddMessage({
            userId: id,
            text: message,
            isFromMe: false,
            dateTime,
          });
        }
      });
    }
  };
  const isProvider = currentUser && currentUser.attributes.profile.publicData.isProvider;
  const setupSocket = async () => {
    if (currentUser) {
      // if(isProvider || isSocketConnectable){
      const socketRes = await onConnectSession();
      // socketRes.emit('setUserUUID', currentUser.id.uuid);
      setEvents(socketRes);
    }
  };

  const setupCheckBookingInterval = async () => {
    if (!checkBookingInterval && isProvider) {
      const interval = setInterval(() => {
        onFetchUserNotifications();
      }, 50000);
      onSetCheckBookingInterval(interval);
    }
  };

  useEffect(() => {
    setupSocket();
  }, [isProvider]);

  useEffect(() => {
    setupCheckBookingInterval();
  }, []);

  url = url ? url : `${config.canonicalRootURL}${props.history.location.pathname}`;
  title = title ? title : "Viveka | World's Largest Coaching Marketplace";
  description = description
    ? description
    : 'Viveka is the world’s largest marketplace for the professional development and personal growth industry. We connect coaches, mentors and speakers to companies and individuals. Our mission is to positively impact 500 million lives with our services.';
  image = image ? image : VivekaLogo;

  // const canonicalRootURL = config.canonicalRootURL;
  const schemaFromProps = Array.isArray(schema) ? schema : [schema];

  const schemaArrayJSONString = JSON.stringify([
    ...schemaFromProps,
    // {
    //   '@context': 'http://schema.org',
    //   '@type': 'Organization',
    //   '@id': `${canonicalRootURL}#organization`,
    //   url: canonicalRootURL,
    //   name: 'Viveka',
    //   // sameAs: sameOrganizationAs,
    //   logo: `${canonicalRootURL}/static/icons/viveka-world.png`,
    //   // address: config.address,
    // },
    // {
    //   '@context': 'http://schema.org',
    //   '@type': 'WebSite',
    //   url: canonicalRootURL,
    //   description:
    //     'Viveka is the world’s largest marketplace for the professional development and personal growth industry. We connect coaches, mentors and speakers to companies and individuals. Our mission is to positively impact 500 million lives with our services.',
    //   name: "Viveka | World's Largest Coaching Marketplace",
    //   publisher: {
    //     '@id': `${canonicalRootURL}#organization`,
    //   },
    // },
    {
      '@context': 'http://schema.org',
      '@type': 'SoftwareApplication',
      name: 'Viveka',
      image: 'https://viveka.world/static/media/viveka-logo.3fc43153.png',
      url: 'https://viveka.world/about',
      author: {
        '@type': 'Person',
        name: 'Viveka',
      },
      datePublished:
        'Please insert valid ISO 8601 date/time here. Examples: 2015-07-27 or 2015-07-27T15:30',
      publisher: {
        '@type': 'Organization',
        name:
          'We are in the business of ‘Reimaging Humanity’ by enabling companies and individuals to reach their highest potential. With our uncompromised success formula, Viveka provides access to a wide range of professional development and personal growth experts and tools. As a result of what we do companies are more productive, individuals are more focused, relationships more fulfilled and lives simply... better.',
      },
    },
    {
      '@context': 'http://schema.org',
      '@type': 'SoftwareApplication',
      name: 'Viveka',
      image: 'https://viveka.world/static/media/viveka-logo.3fc43153.png',
      url: 'https://viveka.world/terms-of-use',
      author: {
        '@type': 'Person',
        name: 'Viveka',
      },
      datePublished:
        'Please insert valid ISO 8601 date/time here. Examples: 2015-07-27 or 2015-07-27T15:30',
      publisher: {
        '@type': 'Organization',
        name:
          'We are in the business of ‘Reimaging Humanity’ by enabling companies and individuals to reach their highest potential. With our uncompromised success formula, Viveka provides access to a wide range of professional development and personal growth experts and tools. As a result of what we do companies are more productive, individuals are more focused, relationships more fulfilled and lives simply... better.',
      },
    },
  ]);

  return (
    <div className={css.flex}>
      {/*<div className={css.wrapperLeft}></div>*/}
      <div id="mainWrapper" className={css.wrapper}>
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
        >
          <title>{title}</title>
          <link rel="canonical" href={url} />
          <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta httpEquiv="Content-Language" content="en" />
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          {keywords.length > 0 && (
            <meta name="keywords" content={keywords.join(', ')} data-react-helmet={true} />
          )}

          <meta itemProp="name" content={title} />
          <meta itemProp="description" content={description} />
          <meta itemProp="image" content={image} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={url} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:url" content={url} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image} />

          <script type="application/ld+json">
            {schemaArrayJSONString.replace(/</g, '\\u003c')}
          </script>
        </Helmet>
        {children}
        <SideChat />
        <StepsInViveka topOffset={topOffset} />
        {/*<GuidForPages />*/}
      </div>
      {/*<div className={css.wrapperRight}></div>*/}
    </div>
  );
}

const mapStateToProps = state => {
  const { socket } = state.socket;
  const { chats } = state.socketChat;
  const { currentUser } = state.user;
  const { checkBookingInterval } = state.utility;

  return { socket, currentUser, chats, checkBookingInterval };
};

const mapDispatchToProps = dispatch => ({
  onConnectSession: () => {
    return dispatch(requestSocket());
  },
  onAddMessage: data => {
    return dispatch(addMessage(data));
  },
  onAddChat: data => {
    return dispatch(addChat(data));
  },
  onSetCheckBookingInterval: interval => dispatch(setCheckBookingInterval(interval)),
  onFetchUserNotifications: () => dispatch(fetchCurrentUserNotifications()),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PageWrapper);
